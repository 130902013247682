import React,{useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Col, Row, Button, Container, Modal } from 'react-bootstrap';
import "../App.css";
import divBg from "../Assets/images/lp.jpg"

function SelfServices() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div style={{backgroundImage:`url(${divBg})`, width:'100%', height:'980px'}}>
      <Container align='center'>
        <h1 style={{fontSize: 40, fontWeight: 'bold'}} className='mb-2' align='center'>&nbsp;</h1>
        <img style={{width: 100, height: 100, marginBottom: 10}} src={process.env.PUBLIC_URL + '/logo_kemenimipas.png'}/>
        <h1 style={{fontSize: 40, fontWeight: 'bold'}} className='mb-4' align='center'>Selamat Datang di Lembaga Pemasyarakatan Kelas I Semarang</h1>
        <h1 style={{fontSize: 30}} className='mb-4' align='center'>Layanan Mandiri (Self Services)</h1>
        <Row>
          <Col className='mb-10'>
              <Card bg='secondary' text='white' border='dark' align='left' >
                <Row className='no-gutters'>
                  <Col>
                    <Card.Header>Cetak Formulir</Card.Header>
                    <Card.Body>
                      <Card.Title style={{fontSize: '300%'}}>Kunjungan</Card.Title>
                      <Card.Text>
                        Secara Mandiri.
                      </Card.Text>
                      <a href='/sskunjungan'><Button variant="danger">Klik Disini</Button></a>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>
          <Col className='mb-10'>
            <Card bg='secondary' text='white' border='dark' align='left' >
                <Row className='no-gutters'>
                  <Col>
                    <Card.Header>Cetak Formulir</Card.Header>
                    <Card.Body>
                      <Card.Title style={{fontSize: '300%'}}>Kirim Obat</Card.Title>
                      <Card.Text>
                        Secara Mandiri.
                      </Card.Text>
                      <a href='/ssprokb'><Button variant="danger">Klik Disini</Button></a>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className='mb-10'>
              <Card bg='primary' text='white' border='dark' align='center' >
                <Row className='no-gutters'>
                  <Col>
                    <Card.Header>Baca Panduan Informasi Seputar Layanan Lapas Semarang</Card.Header>
                    <Card.Body>
                    <Button variant="danger" onClick={handleShow}>Klik Disini</Button>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>
          <Col className='mb-10'>
              <Card bg='primary' text='white' border='dark' align='center' >
                <Row className='no-gutters'>
                  <Col>
                    <Card.Header>Berikan Penilaian Terhadap Layanan Lapas Semarang</Card.Header>
                    <Card.Body>
                    <a href='https://survei-bsk.kemenkumham.go.id/ly/jQ5ZIAon' target="_blank"><Button variant="danger">Klik Disini</Button></a>
                    </Card.Body>
                  </Col>
                </Row>
            </Card>
          </Col>
        </Row>
        <h1 style={{fontSize: 15}} className='mb-4' align='center'>© Copyright {new Date().getFullYear()} - IT LP SEMARANG</h1>
      </Container>
      <Modal backdrop="static" fullscreen="true" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Panduan Layanan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={process.env.PUBLIC_URL + '/ebook-lpsmg.pdf'} style={{width:"100%", height:"600px"}} frameborder="0"></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Selesai
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SelfServices;